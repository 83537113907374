import React, { useState } from 'react'
// import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Button } from 'components/ui'
import { IconDownload } from '../../icons'

import css from './style.module.scss'

import img1 from './img/1.svg'
import img2 from './img/2.svg'
import img3 from './img/3.svg'
import img4 from './img/4.svg'
import img5 from './img/5.svg'
import img6 from './img/6.svg'
import img7 from './img/7.svg'
import img8 from './img/8.svg'
import pdfCalendar from './files/calendar.pdf'

export default function HolidaysOfMonth() {
  const calendar = [
    {
      title: 'Воскресение, 18 декабря 2022 | 24 Кислева',
      subtitle: 'Первая ночь Хануки — 1 свеча',
      description: 'Установите одну свечу с правой стороны ханукии. После наступления темноты произнесите благословения 1, 2 и 3 и зажгите свечу.',
      image: img1,
    },
    {
      title: 'Понедельник, 19 декабря 2022 | 25 Кислева',
      subtitle: 'Вторая ночь Хануки — 2 свечи',
      description: 'Установите две свечи с правой стороны ханукии. После наступления темноты произнесите благословения 1 и 2 и зажгите свечи слева направо.',
      image: img2,
    },
    {
      title: 'Вторник, 20 декабря 2022 | 26 Кислева',
      subtitle: 'Третья ночь Хануки — 3 свечи',
      description: 'Установите три свечи с правой стороны ханукии. После наступления темноты произнесите благословения 1 и 2 и зажгите свечи слева направо.',
      image: img3,
    },
    {
      title: 'Среда, 21 декабря 2022 | 27 Кислева',
      subtitle: 'Четвертая ночь Хануки — 4 свечи',
      description: 'Установите четыре свечи с правой стороны ханукии. После наступления темноты произнесите благословения 1 и 2 и зажгите свечи слева направо.',
      image: img4,
    },
    {
      title: 'Четверг, 22 декабря 2022 | 28 Кислева',
      subtitle: 'Пятая ночь Хануки — 5 свечей',
      description: 'Установите пять свечей с правой стороны ханукии. После наступления темноты произнесите благословения 1 и 2 и зажгите свечи слева направо.',
      image: img5,
    },
    {
      title: 'Пятница, 23 декабря 2022 | 29 Кислева',
      subtitle: 'Шестая ночь Хануки, начинается Шабат — 6 свечей',
      description: 'Установите шесть свечей с правой стороны ханукии. После наступления темноты произнесите благословения 1 и 2 и зажгите свечи слева направо.',
      image: img6,
    },
    {
      title: 'Суббота, 24 декабря 2022 | 1 Тевета',
      subtitle: 'Седьмая ночь Хануки, исход Шабата — 7 свечей',
      description: 'Установите семь свечи с правой стороны ханукии. После окончания Шаббата произнесите благословения 1 и 2 и зажгите свечи слева направо.',
      image: img7,
    },
    {
      title: 'Воскресенье, 25 декабря 2022 | 2 Тевета',
      subtitle: 'Восьмая ночь Хануки — 8 свечей',
      description: 'Установите восемь свечей с правой стороны ханукии. После наступления темноты произнесите благословения 1 и 2 и зажгите свечи слева направо.',
      image: img8,
    },
  ]
  const defaultShowCalendar = 3
  const [stateShowCalendar, setShowCalendar] = useState(defaultShowCalendar)

  return (
    <div className={css.holidays}>
      <div className='container'>
        <div className={css.content}>
          <div className={css.header}>
            <div className={css.headerTitle}>
              <h2 className={css.title}>Календарь<br/>Хануки</h2>
              <h2 className={css.year}>5783<br/>2022</h2>
            </div>
            <div className={css.headerButton}>
              <a href={pdfCalendar} target='_blank' rel='noopener noreferrer'>
                <Button
                  blue
                  text={
                    <React.Fragment>
                      <IconDownload
                        style={{verticalAlign: 'middle'}}
                      />
                      &nbsp;
                      Благословения, PDF
                    </React.Fragment>
                  }
                />
              </a>
            </div>
          </div>
          <div
            className={css.cards}
          >
            {
              calendar.filter((item, index) => index < stateShowCalendar).map((item, index) => (
                <div
                  className={css.card}
                  key={index}
                >
                  <div className={css.cardText}>
                    <h4 className={css.cardTitle}>{ item.title }</h4>
                    <p className={css.cardSubtitle}>{ item.subtitle }</p>
                    <p className={css.cardDescription}>{ item.description }</p>
                  </div>
                  <div className={css.cardImage}>
                    <img
                      src={item.image}
                      alt=''
                    />
                  </div>
                </div>
              ))
            }
          </div>
          {
            calendar.length > defaultShowCalendar &&
              <React.Fragment>
                {
                  stateShowCalendar === defaultShowCalendar &&
                    <a
                      className={css.expandCards}
                      onClick={() => setShowCalendar(calendar.length)}
                    >
                      Развернуть
                    </a>
                }
                {
                  stateShowCalendar === calendar.length &&
                    <a
                      className={css.expandCards}
                      onClick={() => setShowCalendar(defaultShowCalendar)}
                    >
                      Свернуть
                    </a>
                }
              </React.Fragment>
          }
        </div>
      </div>
    </div>
  )
}