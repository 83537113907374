import React from 'react'
import { Link } from 'gatsby'

import css from './block.module.scss'

export default function () {
  return (
    <div className={css.donate}>
      <div className='container'>
        <h2 className={css.title}>
          Поддержать проект
        </h2>
        <p  className={css.text}>
          Любая переведенная вами сумма пойдет на поддержку и развитие еврейской культуры и традиций
        </p>
        <div className={css.buttons}>
          <Link to='/donate' className='button label'>
            Поддержать проект
          </Link>
        </div>
      </div>
    </div>
  )
}
