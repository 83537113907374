import React from 'react'
import { Link } from 'gatsby'
import { Row, Col } from 'styled-bootstrap-grid'
import cn from 'classnames'
import css from './style.module.scss'

import imgLaws from './img/laws.jpg'
import imgHistory from './img/history.jpg'
import imgRabbi from './img/rabbi.jpg'
import imgStories from './img/stories.jpg'

export default function () {
  const cards = [
    {
      name: 'Законы',
      img: imgLaws,
      link: '/blog-laws',
    },
    {
      name: 'История',
      img: imgHistory,
      link: '/blog-history',
    },
    {
      name: 'Ребе',
      img: imgRabbi,
      link: '/blog-rabbi',
    },
    {
      name: 'Рассказы',
      img: imgStories,
      link: '/blog-stories',
    },
  ]

  return (
    <div className={css.whatIs}>
      <div className='container'>
        <div className={css.content}>
          <div className={css.text}>
            <h2 className={css.title}>Что такое Ханука?</h2>
            <p className={css.description}>
              Ханука — это еврейский праздник свечей, которые зажигают в честь чуда, происшедшего при освящении Храма после победы войска Иегуды Маккавея над войсками царя Антиоха в 164 году до нашей эры. Этот праздник начинается 25-го числа еврейского месяца кислева и длится восемь дней.
            </p>
          </div>
          <div className={css.cards}>
            <Row>
              {
                cards.map((item, index) => (
                  <Col
                    col={12}
                    md={6}
                    xl={3}
                    key={ index }
                  >
                    <Link
                      to={item.link}
                      className={css.card}
                      style={{backgroundImage: `url(${item.img})`}}
                    >
                      <div className={cn(css.cardTitle, 'h3-like')}>
                        { item.name }
                      </div>
                      <div className={css.cardGradient}></div>
                    </Link>
                  </Col>
                ))
              }
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}
