import React from 'react'
import css from './style.module.scss'
import { Button } from 'components/ui'
import cn from 'classnames'
import PropTypes from 'prop-types'

HeroView.propTypes = {
  onClickButton: PropTypes.func.isRequired
}

export default function HeroView({
  onClickButton,
  hasOrder,
}) {
  return (
    <div className={css.hero}>
      <div className={cn(css.container, 'container')}>
        <div className={css.textWrap}>
          <h1 className={css.title}>Праздничный набор для Хануки в каждый дом</h1>
          {
            hasOrder &&
              <p className={css.descr}>
                Отметьте Хануку у себя дома с помощью специального набора
              </p>
          }
          {
            !hasOrder &&
              <p className={css.descr}>
                Заявки больше не принимаются
              </p>
          }
          {
            hasOrder &&
              <div className={css.buttonWrap}>
                <Button
                  className={css.button}
                  text="Оставить заявку"
                  onClick={onClickButton}
                />
              </div>
          }
        </div>
      </div>
    </div>
  )
}
