import React from 'react'
import cn from 'classnames'

import css from './card.module.scss'

export default function Card({
  date,
  title,
  html,
}) {
  return (
    <div className={css.marathonCard}>
      <p className={cn(css.date, 'label light-grey')}>{date}</p>
      <h1 className={css.title}>{title}</h1>
      <div
        className={css.article}
        dangerouslySetInnerHTML={{__html: html}}
      />
    </div>
  )
}