import React from 'react'
import cn from 'classnames'
import css from './style.module.scss'

export default function ({
  white
}) {
  return (
    <div className={cn(css.logo, white && css.white)}/>
  )
}
