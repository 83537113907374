import React from 'react'
import cn from 'classnames'

import css from './header.module.scss'

export default function Header() {
  return (
    <div className={css.marathonHeader}>
      <div className='container'>
        <h1><span className='yellow'>#Ханука5781</span> <span className='white'>свет победит тьму</span></h1>
        {/* <div className={css.content}>
        </div> */}
      </div>
    </div>
  )
}