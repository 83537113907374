import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { openSignUpPopup } from 'components/user/actions'
import css from './style.module.scss'

import img1 from './img/1.svg'
import img2 from './img/2.svg'
import img3 from './img/3.svg'

const mapDispatchToProps = dispatch => ({
  openSignUpPopup: bindActionCreators(openSignUpPopup, dispatch),
})

export default connect(
  null,
  mapDispatchToProps
)(
  function({
    openSignUpPopup,
  }) {
    const steps = [
      {
        text: <React.Fragment>Пройдите простую <a onClick={() => openSignUpPopup()}>регистрацию</a> на сайте.</React.Fragment>,
        icon: img1,
      },
      {
        text: <React.Fragment>Оставьте заявку на получение подарочного набора для Хануки для себя или для своих близких в другом городе.</React.Fragment>,
        icon: img2,
      },
      {
        text: <React.Fragment>Получите свой набор и отметьте праздник</React.Fragment>,
        icon: img3,
      },
    ]
    return (
      <div className={css.holidaySet}>
        <div className='container'>
          <h2 className={css.title}>Набор для Праздника у вас дома</h2>
          <div className={css.steps}>
            {
              steps.map((item, index) => (
                <React.Fragment
                  key={index}
                >
                  <div className={css.step}>
                    {/* <div className={css.stepNumber}>{ index + 1 }</div> */}
                    <div className={css.stepIcon}>
                      <img src={item.icon} alt=''/>
                    </div>
                    <p className={css.stepText}>{ item.text }</p>
                  </div>
                  {
                    index < steps.length - 1 &&
                      <div
                        className={css.stepArrow}
                        alt=''
                      />
                  }
                </React.Fragment>
              ))
            }
          </div>
        </div>
      </div>
    )
  }
)
