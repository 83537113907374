import React from 'react'
import css from './style.module.scss'
import { Button } from 'components/ui'
import cn from 'classnames'

export default function () {
  return (
    <div className={css.relatedVideo}>

      <div className={cn('container')}>
        <h2>Советуем посмотреть</h2>

        <div className={css.flex}>
          <div className={css.item}>
            <div className={css.videoBlock}>
              <iframe
                src="https://www.youtube.com/embed/lXU33L6ollk"
                frameBorder="0"
                title="Как зажигают ханукальный светильник"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className={css.item}>
            <div className={css.textBlock}>
              <h3>Как зажигают ханукальный светильник</h3>
              <p>В видео вы получите инструкцию о том, как правильно зажигать ханукальные свечи.</p>
            </div>
          </div>
        </div>

        {/* <div className={css.buttonWrap}>
          <a href="/what-is-rosh-hashanah" aria-label="check">
            <Button ghost={true} text={'Узнать больше'} />
          </a>
        </div> */}

      </div>

    </div>
  )
}
